import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styles from './hero.module.css'

function StoryHero({ headline, alt, image, artist, warning, category }) {
  return (
    <div className={`${styles.hero} theme--dark`}>
      <div className={`${styles.top}`}>
        <div className={styles.headline}>
          <p className={styles.category}>{category}</p>
          <h1 itemProp="headline">{headline}</h1>
        </div>
        <Img
          itemProp="image"
          className={styles.heroImage}
          alt={alt}
          fluid={image.fluid}
          style={{ height: '100vh', maxHeight: '700px' }}
        />
      </div>
      {artist &&
        <div className={`${styles.artist} theme--light`}>
          <h4>Illustrations By:</h4>
          <Link to={`/artist/${artist.slug}`}>{artist.name}</Link>
        </div>
      }
      {warning && 
        <div className="u-container-x u-container-y u-container-story-content">
          <p className="type-small u-no-margin"><strong>Warning:</strong> {warning}</p>
        </div>
      }
    </div>
  )
}

StoryHero.propTypes = {
  alt: PropTypes.string,
}

export default StoryHero

