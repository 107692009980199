import React from 'react'

function IconFacebook(props) {
  return (
    <svg
      width={23}
      height={23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 11.5C23 5.149 17.851 0 11.5 0S0 5.149 0 11.5c0 5.74 4.205 10.498 9.703 11.36v-8.036h-2.92V11.5h2.92V8.966c0-2.882 1.717-4.474 4.344-4.474 1.258 0 2.574.225 2.574.225v2.83h-1.45c-1.428 0-1.874.886-1.874 1.797V11.5h3.19l-.51 3.324h-2.68v8.036C18.795 21.998 23 17.24 23 11.5z"
        fill="#1D1A18"
      />
    </svg>
  )
}

export default IconFacebook
