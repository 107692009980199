import * as React from "react"

function EmailIcon(props) {
  return (
    <svg
      width={28}
      height={19}
      viewBox="0 0 28 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.998 17.617V1.382L18.191 9.5l9.807 8.117zM27.436 0H.561a.542.542 0 00-.33.12l13.771 11.408L27.766.119a.542.542 0 00-.33-.119z"
        fill="#000"
      />
      <path
        d="M27.767 18.88L17.32 10.23l-2.96 2.455a.573.573 0 01-.716 0l-2.96-2.456L.23 18.881c.092.07.204.119.33.119h26.876c.127 0 .232-.05.33-.12zM0 1.382v16.242L9.8 9.5 0 1.382z"
        fill="#000"
      />
    </svg>
  )
}

export default EmailIcon
