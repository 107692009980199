import React, { useState, useRef, useEffect } from 'react'
import { gsap } from "gsap";
import get from 'lodash/get'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { Draggable } from "gsap/Draggable";
import Arrow from '../icons/arrow'
import s from './defund.module.css'

gsap && gsap.registerPlugin(Draggable, ScrollToPlugin);

export default function Defund(props) {
  const { handleSwipe, isDefunded } = props

  const [wasDrug, setWasDrug] = useState(false)
  const dragInstance = useRef(null);
  const dragTarget = useRef(null);
  let siteFooter = null

  useEffect(() => {
    siteFooter = document.getElementById('footer')
    if (siteFooter) siteFooter.style.display = 'none'

    dragInstance.current = Draggable.create(dragTarget.current, {
      type: 'x',
      bounds: '#bounds',
      inertia: true,
      onDrag() {
        const dist = this.x * -1
        if (!wasDrug) setWasDrug(true)
        const poly = `polygon(100% 0, 100% 100%, ${100 - (dist * 0.25)}% 100%, ${100 - (dist * 0.2)}% 0)`
        gsap.to('#headline', {
          clipPath: poly
        })
        handleDefund(dist)
      }
    });
  }, []);

  
  const handleDefund = (x) => {
    if (x > 120) {
      const bounds = document.getElementById('bounds')
      const refund = document.getElementById('refund-inner')
      const xDist = ((bounds.clientWidth / 2) * -1) + dragTarget.current.clientWidth / 2
      const refundHeight = refund.clientHeight
      
      dragInstance.current[0].disable()

      gsap.to('#btn', { x: xDist, rotation: -90, lazy: true, duration: 0.5 })
      gsap.to(window, { duration: 1.5, scrollTo: refund, delay: 0.75, ease: "circ.inOut" })
      gsap.to('#refund', { height: refundHeight, lazy: true, duration: 2.5 })
      gsap.to('#headline', {
        clipPath: 'polygon(100% 0px, 100% 100%, 0px 100%, 0px 0px)', lazy: true, duration: 1
      })
      siteFooter = document.getElementById('footer')
      if (siteFooter) {
        siteFooter.style.display = 'block' 
      } else {
        console.warn('===========================')
      }

      const datalayer = typeof window !== 'undefined' ? window.datalayer : {}
      datalayer.push({
        'event': 'trackEvent',
        'category': 'story actions',
        'action': 'swipe',
        'label':  'defund'
      })
      
      handleSwipe()
    }
  }

  const distDrug = get(dragInstance, '.current[0].x', 0)

  return (
    <div className={`${s.defund} ${!wasDrug ? s.showTease : ''} ${isDefunded ? s.isDefunded : s.aniTease}`}>
    
      <h2 className={s.headline}>Defund Now</h2>

      <div id="headline"
        className={s.headlineRefundWrap}
        style={{
          clipPath: 'polygon(100% 0, 100% 100%, 100% 100%, 100% 0)'
        }}
      >
        <h2 className={s.headlineRefund}>Refund Now</h2>
      </div>

      <div id="bounds" className={s.draggable}>
        <button
          id="btn"
          className={s.arrow}
          name="Drag or click to defund to refund"
          aria-label="Drag or click to defund to refund"
          ref={dragTarget}
          onClick={() => handleDefund(201)}>
          <Arrow fillColor={isDefunded ? 'var(--color-black-warm)' : 'var(--color-white'} />
        </button>
      </div>
    </div>
  )
}

