import React from 'react'
import s from './sticky.module.css'

export default function Sticky(props) {
  const { label, defunds, hide, isDefunded } = props
  const keys = Object.keys(defunds)
  const isHidden = keys.length === 0 || hide
  let total = 0

  keys.map((item) => {
    total = total + defunds[item].cost
  })

  let dynamicLabel = label || "Total Cost of Police"
  if (isDefunded) dynamicLabel = 'reallocation budget'

  return (
    <div className={`${s.root} ${isHidden ? s.hidden : ''} ${isDefunded ? s.isDefunded : ''} theme--dark`}>
      <div className="u-container-x u-container-story-content">
        <h4 className={s.text}>{dynamicLabel} <span className={s.total}>${total.toLocaleString()}</span></h4>  
      </div>
    </div>
  )
}
