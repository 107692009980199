import React from "react"

function SvgComponent(props) {
  const { fillColor } = props
  return (
    <svg
      width={131}
      height={113}
      viewBox="0 0 131 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 56.972h115.39M59.041 104.945L15 56.525 59.041 8"
        stroke={fillColor || "#F7F3EB"}
        strokeWidth={22}
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default SvgComponent
