import React from "react";
import { graphql, Link } from "gatsby";
import get from "lodash/get";
import VisibilitySensor from "react-visibility-sensor";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { SRLWrapper } from "simple-react-lightbox";
import Meta from "../components/meta";
import Layout from "../components/layout";
import Hero from "../components/story/hero";
import Defund from "../components/story/defund";
import RichText from "../components/story/richtext";
import Section from "../components/section";
import Sticky from "../components/story/sticky";
import FBIcon from "../components/icons/facebook";
import TWIcon from "../components/icons/twitter";
import EmailIcon from "../components/icons/email";

import s from "./story.module.css";

export const lightboxOptions = {
  buttons: {
    showDownloadButton: false,
    showThumbnailsButton: false,
    showAutoplayButton: false,
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
};

class StoryTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDefunded: false,
      defunds: {},
      heroIsVisible: false,
    };
  }

  updateDefunds = (val) => {
    const newDefunds = this.state.defunds;
    newDefunds[val.id] = { ...val };
    this.setState({ defunds: newDefunds });
  };

  handleVisibleHero = (isVisible) => {
    this.setState({ heroIsVisible: isVisible });
  };

  handleDatalayer = (label) => {
    const datalayer = typeof window !== "undefined" ? window.datalayer : {};
    datalayer.push({
      event: "trackEvent",
      category: "site share buttons",
      action: "share",
      label: label,
    });
  };

  render() {
    const story = get(this.props, "data.contentfulStory");
    const defaultMeta = get(this.props, "data.site.siteMetadata");
    const hero = get(story, "hero");

    // let metaImage = `https:${get(hero, 'image.fluid.src')}`

    let highlightColors = {};

    if (story.highlightBackground) {
      highlightColors["--color-highlight"] = story.highlightBackground;
    }
    if (story.highlightText) {
      highlightColors["--color-highlight-contrast"] = story.highlightText;
    }

    const { isDefunded, heroIsVisible, defunds } = this.state;

    const socialProps = {
      title: story.headline,
      url: `https://refund2defund.org/stories/${story.slug}`,
    };

    const jsonLD = {
      "@context": "https://schema.org",
      "@type": "article",
      headline: story.headline,
      image: `http:${get(hero, "image.fluid.src")}`,
      description: story.metaDescription,
      author: "defund2refund.org",
      publisher: "defund2refund.org",
      datePublished: "2021",
    };

    return (
      <Layout
        location={this.props.location}
        theme="dark"
        className={isDefunded ? "foo" : "bar"}
      >
        <Meta
          defaultMeta={defaultMeta}
          title={`${story.headline}`}
          description={`${story.metaDescription}`}
          image={`http:${get(hero, "image.fluid.src")}`}
          imageAlt={`${get(hero, "alt")}`}
          jsonLD={jsonLD}
        />

        <Sticky
          defunds={defunds}
          hide={heroIsVisible}
          isDefunded={isDefunded}
        />
        <SRLWrapper options={lightboxOptions}>
          <article
            style={highlightColors}
            itemScope
            itemType="https://schema.org/Article"
          >
            <section className="theme--dark" itemType="articleBody">
              <VisibilitySensor
                onChange={this.handleVisibleHero}
                partialVisibility
              >
                <Hero
                  {...hero}
                  artist={story.artist}
                  headline={story.headline}
                  warning={story.warning}
                  category={story.category}
                />
              </VisibilitySensor>

              <section
                className={`u-container-x u-container-story-content ${s.intro}`}
                itemType="articleSection"
              >
                <RichText {...story.intro} />
              </section>

              <section
                className={`u-container-x u-container-story-content ${s.body}`}
                itemType="articleSection"
              >
                <RichText {...story.body} updateDefunds={this.updateDefunds} />
              </section>
            </section>

            <Defund
              isDefunded={this.state.isDefunded}
              handleSwipe={() => this.setState({ isDefunded: true })}
            />

            <div id="refund" className={` ${s.refund}`}>
              <div id="refund-inner" className="theme--light u-container-y">
                <section
                  className={`u-container-x u-container-story-content ${s.body}`}
                >
                  <RichText {...story.refundBody} />

                  <h2 className={s.rewrite}>How can you rewrite this story?</h2>

                  <h3>Spread the Word</h3>
                  <p>
                    Share Defund to Refund with your community, and continue the
                    conversation.{" "}
                  </p>

                  <div className={s.social}>
                    <FacebookShareButton
                      {...socialProps}
                      beforeOnClick={() => this.handleDatalayer("facebook")}
                    >
                      <FBIcon />
                    </FacebookShareButton>
                    <TwitterShareButton
                      {...socialProps}
                      beforeOnClick={() => this.handleDatalayer("twitter")}
                    >
                      <TWIcon />
                    </TwitterShareButton>
                    <EmailShareButton
                      {...socialProps}
                      beforeOnClick={() => this.handleDatalayer("email")}
                    >
                      <EmailIcon iconFillColor={"black"} size={23} />
                    </EmailShareButton>
                  </div>

                  <hr />

                  <h3>{story.actionHeading}</h3>
                  <RichText {...story.actionCopy} />

                  {story.actionCtaLink && (
                    <a
                      href={story.actionCtaLink}
                      target="_blank"
                      rel="noreferrer"
                      className={s.cta}
                    >
                      {story.actionCtaLabel || "Visit Site"}
                    </a>
                  )}

                  <hr />

                  <p>
                    {" "}
                    Advocate for defunding police to refund your community.
                    Visit our toolkit for resources to show your support and
                    further the conversation.
                  </p>
                  <Link className={s.cta} to="/take-action">
                    Learn More
                  </Link>
                </section>
              </div>
            </div>
          </article>
        </SRLWrapper>

        {isDefunded && (
          <Section
            theme="dark"
            heading="Explore Other Stories"
            variant="stories"
            justStories
          />
        )}
      </Layout>
    );
  }
}

export default StoryTemplate;

export const storyQuery = graphql`
  query StoryBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
        socialInstagram
      }
    }
    contentfulStory(slug: { eq: $slug }) {
      headline
      metaDescription
      category
      slug
      warning
      highlightText
      highlightBackground
      actionHeading
      actionCopy {
        json
      }
      actionCtaLink
      actionCtaLabel
      hero {
        alt
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      intro {
        json
      }
      body {
        json
      }
      refundBody {
        json
      }
      artist {
        name
        slug
      }
    }
  }
`;
